import styled, { css } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { MultilineTruncatedText } from '../../../design_system/Triage/TruncatedText';
import { deprecated, fontSm5 } from '../../../styled/TypeSystem';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const sharedFontStyles = css`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

export const H1 = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin: 0;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${MultilineTruncatedText({})};
  ${deprecated.fontMd2};
`;

export const DueDateWrapper = styled.div<{ overdue: boolean }>`
  display: flex;
  align-items: center;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  ${sharedFontStyles};
  ${({ overdue, theme: { vars } }) => overdue && { color: vars.stateError }};
`;

export const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerMd1};
`;

export const ReadingTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  ${sharedFontStyles};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

// DS Override: Pointer events none was required to get tooltip to show up
export const StyledIcon = styled(Icon)`
  pointer-events: none;
`;
