import { StrictModifier } from 'react-popper';

export const POPPER_MODIFIERS: StrictModifier[] = [
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['bottom-end'],
    },
  },
];

const POPPER_OFFSET_MODIFIER: StrictModifier = {
  name: 'offset',
  options: {
    offset: ({ popper }) => {
      return [0, popper.height / 2];
    },
  },
};

export const POPPER_MODIFIERS_WITH_OFFSET: StrictModifier[] = [
  ...POPPER_MODIFIERS,
  POPPER_OFFSET_MODIFIER,
];
