import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../../../design_system/display/icons/Icon';
import { CollapsibleContent } from '../../../design_system/Triage/layout/CollapsibleSurface';
import { Title } from '../DetailedTitle/Title';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div(
  ({ theme: { constants, vars } }) => css`
    display: flex;
    padding: ${constants.spacerMd2};
    gap: ${constants.spacerSm3};
    align-items: center;
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
    background-color: ${vars.foundationSurface1};
    border-radius: ${constants.borderRadiusLg};
    justify-content: space-between;
    cursor: pointer;
    color: ${vars.textSubdued};
    user-select: none;

    &:hover {
      background-color: ${vars.foundationBase1};
    }
  `
);

const StyledIcon = styled(Icon)<{ isCollapsed: boolean }>`
  transition: transform 0.2s ease-in;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

export type GappedAccordionProps = {
  id: string;
  initialCollapsedState?: boolean;
  title: string;
  children: ReactNode | ReactNode[];
};

const GappedAccordion = ({
  id,
  title,
  initialCollapsedState = false,
  children,
}: GappedAccordionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsedState);

  return (
    <Wrapper id={id}>
      <TitleWrapper
        className='clickable-wrapper'
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <Title fontColor='textSubdued' fontSize='md1' fontWeight='semibold' title={title} />
        <StyledIcon isCollapsed={isCollapsed} name='chevron-down' size='sm' />
      </TitleWrapper>
      <CollapsibleContent isCollapsed={isCollapsed} isCollapsible>
        {children}
      </CollapsibleContent>
    </Wrapper>
  );
};

export default GappedAccordion;
