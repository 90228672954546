import React from 'react';
import styled, { css } from 'styled-components';

import { useTrainingPathContext } from '../../../../../contexts/TrainingPathContext';
import useDisplayFlashOnResponse from '../../../../../hooks/useDisplayFlashOnResponse';
import initTranslations from '../../../../../lib/initTranslations';
import { usePublishTrainingPathMutation } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Avatar from '../../../../design_system/display/avatar/Avatar';
import Tag from '../../../../design_system/display/Tag/Tag';
import Tooltip from '../../../../design_system/display/Tooltip/Tooltip';
import useActiveModalHandler from '../../../publicApplication/utils/useActiveModalHandler';
import BackToProfile from './BackToProfile';
import CancelConfirmationModal from './CancelConfirmationModal';
import DestroyTPConfirmationModal from './DestroyTPConfirmationModal';
import Status from './Status';

const StyledDiv = styled.div(
  ({ theme: { constants, vars } }) => css`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${constants.spacerMd2} ${constants.spacerLg2};
    background: ${vars.foundationSurface1};
    border-bottom: ${constants.borderWidthSm} solid ${vars.borderSurface2};
    position: fixed;
    z-index: 1;
  `
);

const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: ${({ theme: { constants } }) => constants.heightMd};
`;

const Text = styled.h3`
  display: flex;
  margin-left: ${({ theme: { constants } }) => constants.spacerMd1};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const t = initTranslations('training_path.path_config.config_header');

export type ConfigHeaderProps = {
  onRef: (node: HTMLDivElement | null) => void;
};

const ConfigHeader = ({ onRef }: ConfigHeaderProps) => {
  const {
    user,
    updateInProgress,
    trainingPath: { isDraft, isPublished },
  } = useTrainingPathContext();
  const { activeModal, setActiveModal } = useActiveModalHandler();
  const [publishDraft, publishDraftResult] = usePublishTrainingPathMutation({
    fixedCacheKey: 'shared-publish-training-path',
  });

  useDisplayFlashOnResponse({
    result: publishDraftResult,
    errorMessage: t('update_failed'),
    successFunction: () => {
      publishDraftResult.reset();
    },
    errorFunction: () => {
      publishDraftResult.reset();
    },
  });

  return (
    <StyledDiv id={`user-${user.id}-tp-config-header`} ref={(element) => onRef(element)}>
      <Title className='user-title-group'>
        <Avatar image={user.avatar} name={user.name} shape='circle' size='md' />
        <Text>
          {t('description', { name: user.name })}
          <Tag borderless type='accent' />
        </Text>
      </Title>
      <ButtonGroup className='buttons-group'>
        <Status publishing={publishDraftResult.isLoading} />
        <DefaultButton
          buttonType='tertiary'
          disabled={updateInProgress || (!isDraft && !isPublished)}
          id='user-tp-config-header-clear-button'
          onClick={() => setActiveModal('training-path-destroy-modal')}
          text={t('clear_path')}
        />
        {activeModal === 'training-path-destroy-modal' && <DestroyTPConfirmationModal />}
        {isDraft && (
          <>
            <Tooltip id='cancel-draft-tooltip' place='bottom' text={t('cancel_draft_tooltip')} />
            <div data-for='cancel-draft-tooltip' data-tip='true'>
              <DefaultButton
                buttonType='secondary'
                disabled={updateInProgress}
                id='user-tp-config-header-cancel-button'
                onClick={() => setActiveModal('training-path-destroy-draft-modal')}
                text={t('undo_changes')}
              />
              {activeModal === 'training-path-destroy-draft-modal' && <CancelConfirmationModal />}
            </div>
          </>
        )}
        <DefaultButton
          disabled={!isDraft || updateInProgress}
          id='user-tp-config-header-save-button'
          onClick={() => publishDraft({ userId: user.id })}
          text={t('apply')}
        />
        <BackToProfile />
      </ButtonGroup>
    </StyledDiv>
  );
};

export default ConfigHeader;
