import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { deprecated } from '../../../../../styled/TypeSystem';

const ButtonGroup = styled.div`
  display: flex;
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};

  button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    border-left: ${({ theme: { constants, vars } }) =>
      `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  }

  button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

// DS Override: Changing style of the DefaultButton to match the designs in Figma
// https://www.figma.com/file/baTqDfMRWl5ENgO3YYGsD8/Content-Editor-(Content-Blocks)?node-id=15610%3A24289&mode=dev
const StyledButton = styled(DefaultButton)`
  display: flex;
  border: none;
  color: ${({ theme: { vars } }) => vars.textDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};

  ${deprecated.fontSm3};

  svg {
    color: ${({ theme: { vars } }) => vars.textDefault};
  }
`;

const t = initTranslations('editor.topic.header');

export type Props = {
  cancel: () => void;
  update: () => void;
  isLoading: boolean;
};

const RepositionButtons = ({ cancel, update, isLoading }: Props) => {
  return (
    <ButtonGroup>
      <StyledButton
        buttonType='secondary'
        disabled={isLoading}
        iconName='circle-x'
        id='cancel-topic-cover-changes'
        onClick={cancel}
        size='sm'
        text={t('cancel')}
      />
      <StyledButton
        buttonType='secondary'
        disabled={isLoading}
        iconName='check'
        id='save-topic-cover-changes'
        onClick={update}
        size='sm'
        text={t('save')}
      />
    </ButtonGroup>
  );
};

export default RepositionButtons;
