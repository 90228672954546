import styled from 'styled-components';

import BaseLink from '../../../../design_system/Link/Link';
import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';
import { deprecated, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    max-width: 24rem;
  }
`;

export const TitlesSection = styled.div`
  margin: 0 0 ${({ theme: { constants } }) => constants.spacerLg1} 0;
  text-align: center;
  width: 18rem;

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: 24rem;
  }
`;

export const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${deprecated.fontMd4};
`;

export const Subtitle = styled.h2`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0;
  ${fontSm5};
`;

export const SignInSection = styled.div`
  margin-bottom: 6rem;
  ${fontSm5};
  @media (min-width: ${mediaBreakpointPxLg}) {
    margin-bottom: 8rem;
  }
`;

export const InfoText = styled.div`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textSubdued};
  width: 65%;
  padding: 0 ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm4};

  @media (min-width: ${mediaBreakpointPxLg}) {
    width: unset;
    ${fontSm5};
  }
`;

// DS Override: Needed to not show external icons for these links
export const TermsLink = styled(BaseLink)`
  color: ${({ theme: { vars } }) => vars.textDefault};
  svg {
    display: none;
  }
`;

export const SubmitButtonWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
`;
