import React from 'react';
import styled, { css } from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import {
  CollapsibleContent,
  IconWrapper,
  Props,
  StyledIconButton,
  transformThreeDotMenuOptions,
} from '../../../../../design_system/Triage/layout/CollapsibleSurface';
import ThreeDotMenu from '../../../../../design_system/Triage/menus/ThreeDotMenu';
import { deprecated } from '../../../../../styled/TypeSystem';

const BioCollapsibleSurfaceWrapper = styled.section`
  ${({ theme: { constants, vars } }) => css`
    background: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.foundationBase1};
    box-shadow: 0 5px 15px ${vars.shadowColorTopMedium};
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerLg1} ${constants.spacerMd3};
  `}
`;

const Header = styled.header<{ collapsed: boolean; isCollapsible: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme: { constants }, collapsed, isCollapsible }) =>
    isCollapsible && collapsed ? 0 : constants.spacerMd3};
  transition: padding-bottom 0.5s ease-out;
`;

const Title = styled.h1`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  margin: 0;
  ${deprecated.fontMd2};
`;

const t = initTranslations('collapsible_surface');

const BioCollapsibleSurface = ({
  children,
  title,
  chevronClassName = 'bio-collapsible-surface-chevron',
  className = 'bio-collapsible-surface',
  collapseDispatch,
  id,
  isCollapsible = true,
  isCollapsed,
  threeDotMenuOptions,
  threeDotMenuId,
}: Props) => {
  const transformedThreeDotMenuOptions = transformThreeDotMenuOptions(
    threeDotMenuOptions,
    isCollapsed,
    collapseDispatch
  );

  return (
    <BioCollapsibleSurfaceWrapper className={className} id={id}>
      <Header collapsed={isCollapsed} isCollapsible={isCollapsible}>
        <Title>{title}</Title>
        <IconWrapper>
          {threeDotMenuId &&
            transformedThreeDotMenuOptions &&
            transformedThreeDotMenuOptions.length > 0 && (
              <ThreeDotMenu
                id={threeDotMenuId}
                menuOptions={transformedThreeDotMenuOptions}
                menuPlacement='left-start'
              />
            )}
          {isCollapsible && (
            <StyledIconButton
              $collapsed={isCollapsed}
              ariaLabel={isCollapsed ? t('aria_label.show_content') : t('aria_label.hide_content')}
              className={chevronClassName}
              name='chevron-down'
              onClick={() => collapseDispatch({ type: 'toggle-chevron' })}
            />
          )}
        </IconWrapper>
      </Header>
      <CollapsibleContent isCollapsed={isCollapsed} isCollapsible={isCollapsible}>
        {children}
      </CollapsibleContent>
    </BioCollapsibleSurfaceWrapper>
  );
};

export default BioCollapsibleSurface;
