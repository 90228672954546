import React, { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import styled, { css } from 'styled-components';

import DefaultUploadAvatarIcon from '../../../images/default_upload_avatar_icon.svg';
import { getCroppedImg } from '../../../lib/cropImage';
import { readFile } from '../../../lib/readFile';
import { userInitials } from '../../../lib/userNaming';
import { AvatarObject } from '../../../types/Avatar';
import { PixelCrop } from '../../../types/Cropper';
import DefaultButton from '../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm } from '../../styled/Breakpoint';
import { deprecated, fontMd1, fontMd5, fontSm5 } from '../../styled/TypeSystem';
import ImageUploadControls from './ImageUploadControls';
import { ImageForm, ImageIconSize, VerticalAlign } from './ImageUploadTypes';

const convertWidthSizeToRem = (size: ImageIconSize, form: ImageForm) => {
  switch (size) {
    case 'sm':
      return form === 'circle' ? '4rem' : '8rem';
    case 'lg':
      return form === 'circle' ? '8rem' : '16rem';
  }
};

const convertSizeToRem = (size: ImageIconSize) => {
  switch (size) {
    case 'sm':
      return '4rem';
    case 'lg':
      return '8rem';
  }
};

const ImageUploadContainer = styled.div`
  padding: ${({ theme: { constants } }) => constants.spacerMd2};
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
`;

const ImageUploadTopContainer = styled.div<VerticalAlignProps>`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlign};
  flex-direction: ${({ imageForm }) => (imageForm === 'circle' ? 'row' : 'column')};

  @media (min-width: ${mediaBreakpointPxSm}) {
    flex-direction: row;
  }
`;

const ImageUploadDefaultIcon = css`
  background-image: url(${DefaultUploadAvatarIcon});
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

const ImageUploadDefaultBackground = css<BackgroundImageDefaultProps>`
  background-image: url(${({ backgroundImageDefault }) => backgroundImageDefault});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

const ImageUploadInitials = css<ImageIconSizeProps>`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  ${({ imageSize }) => (imageSize == 'sm' ? fontMd5 : deprecated.fontLg4)};
`;

const ImageUploadView = styled.div<
  ShowInitialsProps &
    ImageIconSizeProps &
    BackgroundImageColorProps &
    ImageFormProps &
    BackgroundImageDefaultProps &
    BackgroundImageSrcProps
>`
  position: relative;
  border-radius: ${({ imageForm, theme: { constants } }) =>
    imageForm === 'circle' ? constants.borderRadiusCircle : '0'};
  overflow: hidden;
  will-change: transform;
  background-color: ${({ backgroundImageColor, theme: { vars } }) =>
    backgroundImageColor ? backgroundImageColor : vars.textDisabled};
  ${({ showInitials, imageSrc, backgroundImageDefault }) =>
    !imageSrc &&
    (backgroundImageDefault
      ? ImageUploadDefaultBackground
      : showInitials
      ? ImageUploadInitials
      : ImageUploadDefaultIcon)};
  width: ${({ imageSize, imageForm = 'circle' }) => convertWidthSizeToRem(imageSize, imageForm)};
  height: ${({ imageSize }) => convertSizeToRem(imageSize)};
`;

const ImageUploadBlock = styled.div<ImageIconSizeProps & ImageFormProps>`
  padding-left: ${({ theme: { constants } }) => constants.spacerMd2};
  width: calc(
    100% -
      ${({ imageSize, imageForm = 'circle' }) =>
        imageForm === 'circle' ? convertWidthSizeToRem(imageSize, imageForm) : 0}
  );
  margin-top: ${({ theme: { constants }, imageForm }) =>
    imageForm !== 'circle' && constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: 0;
    width: calc(
      100% - ${({ imageSize, imageForm = 'circle' }) => convertWidthSizeToRem(imageSize, imageForm)}
    );
  }
`;

const ImageUploadTitle = styled.h4`
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${fontSm5};
  }
  ${fontMd1};
`;

const ImageUploadText = styled.p`
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  margin-top: 0;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${fontSm5};
  }
  ${fontMd1};
`;

const InputFile = styled.input`
  opacity: 0;
  position: absolute;
  inset: 0;
  cursor: pointer;
`;

interface ImageUploadProps {
  imageUrl?: string | undefined;
  backgroundImageColor?: string;
  backgroundImageDefault?: string;
  setImage: (e: SetStateAction<AvatarObject>) => void;
  title: string;
  text: string;
  uploadButtonText: string;
  resetButtonText: string;
  name?: string;
}

interface ShowInitialsProps {
  showInitials?: boolean;
}

interface VerticalAlignProps extends ImageFormProps {
  verticalAlign?: VerticalAlign;
}

interface ImageIconSizeProps {
  imageSize: ImageIconSize;
}

interface ImageFormProps {
  imageForm?: ImageForm;
}

interface BackgroundImageColorProps {
  backgroundImageColor?: string;
}

interface BackgroundImageDefaultProps {
  backgroundImageDefault?: string | undefined;
}

interface BackgroundImageSrcProps {
  imageSrc: string | undefined;
}

export type Props = ImageUploadProps &
  ShowInitialsProps &
  ImageIconSizeProps &
  ImageFormProps &
  VerticalAlignProps;

const ImageUpload = ({
  imageUrl,
  backgroundImageColor,
  backgroundImageDefault = undefined,
  setImage,
  title,
  text,
  uploadButtonText,
  resetButtonText,
  imageSize = 'sm',
  imageForm = 'circle',
  showInitials = false,
  name = '',
  verticalAlign = 'flex-start',
}: Props) => {
  // can be a base64 image ('data:image...') or a URL ('/uploads/user/avatar/22/profile_avatar.png?...')
  const [imageSrc, setImageSrc] = useState<string | undefined>(imageUrl);

  // coordinates used to crop the preview
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  // primitives must be used for the useEffect dependencies
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState<number>(1);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(320);
  const [height, setHeight] = useState(320);

  // state used to fit image to aspect ratio
  const [uploadedImageSize, setUploadedImageSize] = useState({
    width: 1,
    height: 1,
  });
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = (await readFile(file)) as string;
      setImageSrc(imageDataUrl);
    }
  };

  const onCropComplete = useCallback((_, pixelCrop: PixelCrop) => {
    if (pixelCrop) {
      const { x, y, width, height } = pixelCrop;
      setX(x);
      setY(y);
      setWidth(width);
      setHeight(height);
    }
  }, []);

  const resetImage = () => {
    setImage((prevState: AvatarObject) => {
      return { ...prevState, avatar: undefined };
    });
    setImageSrc(undefined);
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
  };

  const imageAspect = (url: string) => {
    const img = new Image();
    img.addEventListener('load', function () {
      setUploadedImageSize({
        width: this.naturalWidth,
        height: this.naturalHeight,
      });
    });
    img.src = url;
  };

  useEffect(() => {
    if (imageSrc) {
      (async () => {
        try {
          const croppedImage = await getCroppedImg({
            imageSrc,
            pixelCrop: { x, y, width, height },
            rotation,
          });
          setImage((prevState: AvatarObject) => {
            return { ...prevState, avatar: croppedImage ? (croppedImage as string) : undefined };
          });
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [x, y, width, height, rotation, imageSrc, setImage]);

  useEffect(() => {
    if (imageSrc) {
      imageAspect(imageSrc);
    }
  }, [imageSrc]);

  const aspect = imageForm === 'circle' ? 1 : uploadedImageSize.width / uploadedImageSize.height;

  return (
    <ImageUploadContainer>
      <ImageUploadTopContainer imageForm={imageForm} verticalAlign={verticalAlign}>
        <ImageUploadView
          backgroundImageColor={backgroundImageColor}
          backgroundImageDefault={backgroundImageDefault}
          imageForm={imageForm}
          imageSize={imageSize}
          imageSrc={imageSrc}
          showInitials={showInitials}
        >
          {imageSrc ? (
            <Cropper
              aspect={aspect}
              classes={{ mediaClassName: 'avatar-cropped-image' }}
              crop={crop}
              image={imageSrc}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onRotationChange={setRotation}
              onZoomChange={setZoom}
              rotation={rotation}
              zoom={zoom}
            />
          ) : (
            <div className='notranslate'>
              {showInitials && userInitials(name)}
              <InputFile
                accept='image/*'
                name='image'
                onChange={onFileChange}
                ref={inputFileRef}
                type='file'
              />
            </div>
          )}
        </ImageUploadView>
        <ImageUploadBlock imageForm={imageForm} imageSize={imageSize}>
          <ImageUploadTitle>{title}</ImageUploadTitle>
          <ImageUploadText>{text}</ImageUploadText>
          {imageSrc ? (
            <DefaultButton
              buttonType='secondary'
              id='photo-loader-remove-image-button'
              onClick={resetImage}
              size='sm'
              text={resetButtonText}
            />
          ) : (
            <DefaultButton
              buttonType='secondary'
              id='photo-loader-upload-image-button'
              onClick={() => inputFileRef.current?.click()}
              size='sm'
              text={uploadButtonText}
            />
          )}
        </ImageUploadBlock>
      </ImageUploadTopContainer>
      {imageSrc && (
        <ImageUploadControls
          rotation={rotation}
          setRotation={setRotation}
          setZoom={setZoom}
          zoom={zoom}
        />
      )}
    </ImageUploadContainer>
  );
};

export default ImageUpload;
