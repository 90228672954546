import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { useAbilityToEditUser } from '../../../../../hooks/users/ProfileSharedAbilities';
import initTranslations from '../../../../../lib/initTranslations';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetUserProgressQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Tag from '../../../../design_system/display/Tag';
import { deprecated, fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import ProgressBlock from './ProgressBlock/ProgressBlock';

export interface TrainingPathProps {
  userId: number;
}

const Container = styled.section`
  ${({ theme: { constants, vars } }) => css`
    background: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.foundationBase1};
    box-shadow: ${vars.shadowTopMedium};
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerMd3};
    font-weight: ${constants.fontRegular};
    color: ${vars.textDefault};
  `};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme: { constants } }) => constants.spacerLg2};
`;

const Title = styled.div`
  display: flex;
  ${deprecated.fontMd2};
`;

const Description = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm5};
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => theme.constants.spacerMd3};
  ${fontSm5};
`;

const t = initTranslations('user_profile.training_path_profile_card');

const TrainingPath = ({ userId }: TrainingPathProps) => {
  const { data, error } = useGetUserProgressQuery(userId);
  const { slug } = useCurrentAccount();
  const ableToEditUser = useAbilityToEditUser(userId);

  if (error) return <BasicErrorDisplay error={error} />;
  if (!data) return <></>;

  return (
    <Container id='profile-training-path-card'>
      <Header>
        <Title>
          {t('title')}
          <Tag borderless type='accent' />
          {/* later we will replace this Tag with the following Badge */}
          {/* <Badge text={t('badge_text')} type='info' /> */}
        </Title>
        {ableToEditUser && (
          <DefaultButton
            behavesAs='a'
            buttonType='secondary'
            href={routes.userTrainingPathConfig({ slug, id: userId }).href}
            id='customize-training-path'
            text={t('customize')}
          />
        )}
      </Header>

      <Description>
        {ableToEditUser
          ? t('description_ability_to_manage_profile', { name: data.name })
          : t('description')}
      </Description>

      <ProgressBlock {...data} />

      <Footer>
        <RouterLink id='see-training-path' to={routes.userTrainingPath({ slug, id: userId })}>
          {t('link_text')}
        </RouterLink>
      </Footer>
    </Container>
  );
};

export default TrainingPath;
