import { useFormikContext } from 'formik';
import React, { FC, useMemo, useState } from 'react';

import useCurrentAccount from '../../../../../../hooks/useCurrentAccount';
import { ReactComponent as TrainualLogo } from '../../../../../../images/trainual-logo-icon.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../../lib/userNaming';
import { useDispatchSetShowModal } from '../../../../../../redux/domains/modalsSlice/modalsSlice';
import { useAccountTerminology } from '../../../../../AccountTerminologyProvider';
import SplitButton from '../../../../../design_system/buttons/SplitButton';
import { MenuOptionProps } from '../../../../../design_system/core/MenuOptions';
import Poppable from '../../../../../Poppable';
import useActiveMenuHandler from '../../../../publicApplication/utils/useActiveMenuHandler';
import ContentFlyout from '../../../../shared/ContentFlyout/ContentFlyout';
import { SelectedItem } from '../../../../shared/ContentFlyout/types';
import OutsideClickHandler from '../../../../shared/OutsideClickHandler';
import {
  ResponsibilityDetailsFormData,
  TResponsibilityContentUrl,
} from '../ResponsibilityDetails/types';
import { SectionWrapper } from '../styles';
import { POPPER_MODIFIERS, POPPER_MODIFIERS_WITH_OFFSET } from './_data';
import ContentUrlsList from './ContentUrlsList/ContentUrlsList';
import EmptyState from './EmptyState/EmptyState';
import ContentUrlForm from './Forms/ContentUrlForm';
import { ContentUrlFormWrapper, Header, StyledTitle, Wrapper } from './styles';
import { ResponsibilityLinkedContentProps } from './types';
import { CONTENTABLE_URLS } from './utils';

const t = initTranslations('delegation_planner.modals.responsibility_details_modal.linked_content');

const ResponsibilityLinkedContent: FC<ResponsibilityLinkedContentProps> = ({
  isGroup,
  responsibiliableName,
  responsibiliableId,
}) => {
  const { slug, delegationPlannerEnabled } = useCurrentAccount();
  const dispatchShowModal = useDispatchSetShowModal();
  const { productTerm } = useAccountTerminology();
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  const { values, setValues } = useFormikContext<ResponsibilityDetailsFormData>();
  const filteredUrls = useMemo(() => {
    return values.contents_urls.filter((url) => !url._destroy);
  }, [values.contents_urls]);
  const displayEmptyState = !filteredUrls.length;
  const userName = userFirstName(responsibiliableName || '');
  const emptyStateTitle = t('empty_state', { name: isGroup ? t('this_group') : userName });

  const menuId = 'connect-content-menu';

  const { closeMenu, handleMenuClick, isMenuOpen } = useActiveMenuHandler({
    menuId,
  });

  const toggleShowOptionsDropdown = () => {
    if (isMenuOpen) {
      closeMenu();
    }
    setShowOptionsDropdown(!showOptionsDropdown);
  };

  const buttonMenuOptions: MenuOptionProps[] = [
    {
      customIcon: <TrainualLogo />,
      id: 'connect-from-trainual',
      onClick: () => {
        toggleShowOptionsDropdown();
        handleMenuClick();
      },
      title: t('options.from_trainual', { productTerm }),
    },
    {
      iconName: 'link-simple',
      iconWeight: 'regular',
      id: 'connect-from-url',
      onClick: () => {
        toggleShowOptionsDropdown();
        setShowAddForm(true);
      },
      title: t('options.from_url'),
    },
    {
      iconName: 'plus',
      iconWeight: 'regular',
      id: 'create-new-curriculum',
      onClick: () => {
        dispatchShowModal('delegationCreateAndConnectCurriculumModal', true);
      },
      title: t('options.create_and_connect'),
    },
  ];

  const handleContentFlyoutSubmit = (currentlySelectedItems: SelectedItem[]) => {
    const contentUrls: TResponsibilityContentUrl[] = currentlySelectedItems.map((item) => {
      const { id, title } = item;
      const url = CONTENTABLE_URLS({ id, slug })[item.__typename];

      return {
        url,
        title,
        contentableId: item.id,
        contentableType: item.__typename,
      };
    });

    setValues({
      ...values,
      contents_urls: [...values.contents_urls, ...contentUrls],
    });

    closeMenu();
  };

  const handleAddContentUrl = (title: string, url: string) => {
    const newContentUrl = { title, url };
    setValues({
      ...values,
      contents_urls: [newContentUrl, ...values.contents_urls],
    });
    setTitle('');
    setUrl('');
    setShowAddForm(false);
  };

  const handleCancelAddForm = () => {
    setTitle('');
    setUrl('');
    setShowAddForm(false);
  };

  const previouslySelectedItems = useMemo(() => {
    const contentUrlFromAccount = values.contents_urls.filter(
      (contentUrl) => contentUrl.contentableType && contentUrl.contentableId
    );

    return contentUrlFromAccount.map((contentUrl) => ({
      id: contentUrl.contentableId,
      title: contentUrl.title,
      __typename: contentUrl.contentableType,
      _destroy: contentUrl._destroy,
    })) as SelectedItem[];
  }, [values.contents_urls]);

  const poppableTrigger = () => (
    <OutsideClickHandler onOutsideClick={() => setShowOptionsDropdown(false)}>
      <SplitButton
        buttonType='tertiary'
        isActiveMenu={showOptionsDropdown}
        mainButtonOnClick={handleMenuClick}
        mainButtonType='button'
        mainMenuButtonId='connect-content-button'
        menuId='connect-content-menu'
        menuOptions={buttonMenuOptions}
        setActiveMenu={toggleShowOptionsDropdown}
        text={t('connect')}
      />
    </OutsideClickHandler>
  );

  const poppableItem = () => (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <ContentFlyout
        contentAssigneeId={responsibiliableId}
        contentAssigneeType={isGroup ? 'Group' : 'User'}
        onCancel={closeMenu}
        onSubmit={handleContentFlyoutSubmit}
        previouslySelectedItems={previouslySelectedItems}
      />
    </OutsideClickHandler>
  );

  const renderFormOrEmptyState = () => {
    if (showAddForm) {
      return (
        <ContentUrlForm
          handleCancel={handleCancelAddForm}
          handleSave={handleAddContentUrl}
          initialTitle={title}
          initialUrl={url}
        />
      );
    } else {
      return <EmptyState title={emptyStateTitle} />;
    }
  };

  const renderFormAndContentList = () => {
    return (
      <>
        {showAddForm && (
          <ContentUrlFormWrapper>
            <ContentUrlForm
              handleCancel={handleCancelAddForm}
              handleSave={handleAddContentUrl}
              initialTitle={title}
              initialUrl={url}
            />
          </ContentUrlFormWrapper>
        )}
        <ContentUrlsList isEditable urls={values.contents_urls} />
      </>
    );
  };

  const popperModifiers = delegationPlannerEnabled
    ? POPPER_MODIFIERS
    : POPPER_MODIFIERS_WITH_OFFSET;

  return (
    <SectionWrapper>
      <Header>
        <StyledTitle>{t('connected_content')}</StyledTitle>

        <Poppable
          isOpen={isMenuOpen}
          item={poppableItem()}
          modifiers={popperModifiers}
          onClick={() => ({})}
          placement='bottom-end'
          trigger={poppableTrigger()}
        />
      </Header>

      <Wrapper>{displayEmptyState ? renderFormOrEmptyState() : renderFormAndContentList()}</Wrapper>
    </SectionWrapper>
  );
};

export default ResponsibilityLinkedContent;
