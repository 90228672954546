import styled from 'styled-components';

import Badge from '../../../../../design_system/display/badge';
import { deprecated } from '../../../../../styled/TypeSystem';
import { CardWrapper } from '../../shared/styles';

export const CardDetailsSectionWrapper = styled(CardWrapper)<{
  hasCardDetails?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: unset;

  ${({ hasCardDetails, theme: { vars } }) =>
    !hasCardDetails && {
      'border-color': vars.stateError,
    }};
`;

export const OverlayContentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  width: max-content;
`;

// DS Override - to match design
export const StyledBadge = styled(Badge)`
  background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${deprecated.fontSm3};
`;

export const SeatsAndBillingIntervalWrapper = styled.div`
  align-items: end;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  justify-content: space-between;
`;

export const BillingIntervalWrapper = styled.div<{ isLoading: boolean }>`
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 10.375rem;
  min-height: ${({ theme: { constants } }) =>
    `calc(${constants.heightLg} + ${constants.heightSm})`};
  width: 100%;
  ${({ isLoading }) => isLoading && 'opacity: 0.5;'}
`;
