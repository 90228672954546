import styled from 'styled-components';

import ThemedImage from '../../../design_system/ThemedImage';
import Card from '../../../design_system/Triage/layout/Card';
import { deprecated, fontSm5 } from '../../../styled/TypeSystem';

export const StyledCard = styled(Card)`
  padding: 1.125rem ${({ theme: { constants } }) => constants.spacerMd1} 4.1rem;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  color: ${({ theme: { vars } }) => vars.foundationSurface1};
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  color: ${({ theme: { vars } }) => vars.textDefault};
`;

export const StyledCardImage = styled(ThemedImage)`
  max-height: 7rem;
  max-width: 100%;
`;

export const CardTitle = styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  color: ${({ theme: { vars } }) => vars.textDefault};
  ${deprecated.fontMd2};
`;

export const CardDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
`;

export const CardReadMore = styled.span`
  display: inline-block;
  text-decoration: underline;
  margin-top: ${({ theme: { constants } }) => constants.spacerSm2};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  ${fontSm5};
`;
