import styled from 'styled-components';

import IconButton from '../../../design_system/buttons/IconButton';
import { deprecated, fontSm4 } from '../../../styled/TypeSystem';

export const TitledPopupContainer = styled.div`
  min-width: 10rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusMd};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  overflow: hidden;

  z-index: 1100;
`;

export const TitledPopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerSm3};
  padding: ${({ theme: { constants } }) => `${constants.spacerMd1} ${constants.spacerMd2}`};
  border-bottom: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
`;

export const Title = styled.span`
  color: ${({ theme: { vars } }) => vars.textSubdued};

  ${fontSm4};
`;

export const CloseButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};

  svg {
    ${deprecated.fontMd2};
  }
`;
