import { random } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import LoaderSrc from '../../../../../images/loaders/create-account-loader.gif';
import initTranslations from '../../../../../lib/initTranslations';
import P from '../../../../design_system/text/P';
import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { deprecated, fontLg1, fontMd1 } from '../../../../styled/TypeSystem';

const LoaderWrapper = styled.div`
  width: 100%;
`;

const LoaderTitle = styled.h2`
  margin-bottom: ${({ theme: { constants } }) => constants.spacerSm3};
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-bottom: ${({ theme: { constants } }) => constants.spacerMd3};
    ${fontLg1};
  }
  ${deprecated.fontMd4};
`;

const LoaderGif = styled.img`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// DS Override: Need to match existing design
const LoaderText = styled(P)`
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  @media (min-width: ${mediaBreakpointPxSm}) {
    ${deprecated.fontMd4};
  }
  ${fontMd1};
`;

const t = initTranslations('public_application.registration.create_account_loader');

const CreateAccountLoader = () => {
  const randomTextNumber = useMemo(() => {
    return random(1, 10);
  }, []);

  return (
    <LoaderWrapper className='create-account-loader'>
      <LoaderTitle>{t('title')}</LoaderTitle>
      <LoaderGif src={LoaderSrc} />
      <LoaderText text={t(`text.text_${randomTextNumber}`)} />
    </LoaderWrapper>
  );
};

export default CreateAccountLoader;
