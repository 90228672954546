import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { deprecated, fontLg2, fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

export const ChooseVariationContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  max-width: 28rem;
`;

export const ChooseVariationHeader = styled.div`
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};
  display: flex;
  align-items: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontLg2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
  }
`;

export const ChooseVariationBullet = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidth2xl} solid ${vars.borderDisabled}`};
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 12vh;
    bottom: 50px;
    left: 11px;
    background-color: ${({ theme: { vars } }) => vars.borderSurface1};
    position: absolute;
  }
`;

export const ChooseVariationInfoWrapper = styled.div`
  max-width: 24rem;
`;

export const ChooseVariationTitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontMedium};
  ${fontSm5};
`;

export const ChooseVariationSubtitle = styled.div`
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};
  ${fontSm4};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: ${({ theme: { constants } }) => constants.spacerMd2};
  width: 19rem;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: 26rem;
    margin-top: 5rem;
  }
`;

export const VariationCardsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd3};

  > :last-child {
    margin-left: ${({ theme: { constants } }) => constants.spacerSm3};
  }
`;

export const CardContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 14.5rem;
  max-width: 13rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  border: ${({ isActive, theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${
      isActive ? vars.accentPrimaryDefault : vars.borderSurface2
    }`};
  padding: ${({ theme: { constants } }) => constants.spacerSm3};
  cursor: pointer;
  flex: 50%;

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: ${({ theme: { constants } }) => constants.spacerMd3}
      ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

export const CardEmoji = styled.div`
  ${deprecated.fontLg4}
`;

export const CardTitle = styled.span`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  ${fontSm5}
`;

export const CardDescription = styled.span`
  text-align: center;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-top: ${({ theme: { constants } }) => constants.spacerSm3};
  max-width: 10.5rem;

  ${fontSm5};
`;
