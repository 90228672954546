import styled, { css } from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../../../styled/Breakpoint';
import { deprecated } from '../../../../../../styled/TypeSystem';

export const ConnectedContentInfoCard = styled.p`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 23rem;
    font-weight: ${constants.fontRegular};
    margin-bottom: ${constants.spacerMd3};
    border-radius: ${constants.borderRadiusMd};
    background-color: ${vars.stateBackgroundInfo};
    padding: ${constants.spacerSm3};
    gap: ${constants.spacerSm2};
    color: ${vars.textDefault};
    margin-left: ${({ theme: { constants } }) =>
      `calc(${constants.spacerSm3} + ${constants.borderWidthSm})`};
    ${deprecated.fontSm3};

    @media (min-width: ${mediaBreakpointPxSm}) {
      margin-bottom: 0;
    }
  `};
`;
